<template>
  <div>
    <div class="flex space-x-4 mb-4 bg-white p-3 shadow-lg rounded-lg">
      <button v-for="component in components" :key="component.name" :class="tabClass(component.name)" @click="setActiveTab(component.name)" :aria-selected="activeTab === component.name">
        {{ component.title }}
      </button>
    </div>

    <div class="text-sm bg-white px-4 rounded-lg pt-4">
      <component :is="currentComponent" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref, computed } from "vue";
import YelloPageCategory from "./YelloPageCategory.vue";
import ListPage from "./ListPage.vue";
import ListPage2 from "./ListPage2.vue";
import YellowPage from "./YellowPage.vue";
export default {
  name: "AdminYellowPage",
  components: {},
  setup() {
    const activeTab = ref("category");

    const tabClass = (tab) =>
      `py-2 px-4 rounded-t-lg text-sm focus:outline-none ${
        activeTab.value === tab ? "text-green border-b-2 border-green font-bold" : "text-gray-600 hover:text-gray-700 border-b-2 border-transparent"
      }`;

    const components = [
      {
        title: "Категори",
        name: "category",
        component: YelloPageCategory,
      },
      {
        title: "Компани жагсаалт",
        name: "list1",
        component: ListPage,
      },

      {
        title: "Компани жагсаалт",
        name: "list2",
        component: ListPage2,
      },
      {
        title: "Шар ном",
        name: "yellowpage",
        component: YellowPage,
      },
    ];

    const currentComponent = computed(() => {
      const matchedComponent = components.find((comp) => comp.name === activeTab.value);
      if (!matchedComponent) {
        console.warn(`Component not found for tab: ${activeTab.value}`);
        return null;
      }
      return matchedComponent.component;
    });

    const setActiveTab = (tabName) => {
      activeTab.value = tabName;
    };

    return {
      activeTab,
      tabClass,
      components,
      currentComponent,
      setActiveTab,
    };
  },
};
</script>
