<template>
  <div v-if="companies.length > 0" class="text-xs">
    <table class="table-auto w-full border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 px-4 py-2">№</th>
          <th class="border border-gray-300 px-4 py-2">Үйлдэл</th>
          <th class="border border-gray-300 px-4 py-2">Нэр</th>
          <th class="border border-gray-300 px-4 py-2">И-мэйл хаяг</th>
          <th class="border border-gray-300 px-4 py-2">Лого</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(company, index) in companies" :key="index">
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ index + 1 }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            <div class="flex gap-2 justify-center">
              <button
                @click="editCompany(company)"
                class="bg-sky-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-pen" />
              </button>
              <button
                @click="deleteCompany(company.id, company.name)"
                class="bg-red-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ company.name }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ company.email }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            <img
              :src="company.logo"
              alt="Logo"
              class="w-20 h-20 object-contain mx-auto"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { ref, onMounted } from "vue";

export default {
  name: "MMCompaniesTable",
  props: {
    data: Array,
  },
  setup(props, { emit }) {
    const companies = ref([]);

    onMounted(() => {
      if (props.data) {
        companies.value = props.data;
      }
    });

    const editCompany = (company) => {
      emit("setMode", "B", company);
    };

    const deleteCompany = async (id, name) => {
      if (!window.confirm(`${name}-г устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(
          `/delete/member-companies?id=${id}`
        );
        if (res.status === 200) {
          emit("fetchData");
        }
      } catch (err) {
        return err;
      }
    };

    return {
      companies,
      editCompany,
      deleteCompany,
    };
  },
};
</script>
