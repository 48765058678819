<template>
  <div class="flex justify-center">
    <main
      class="w-full min-[1200px]:w-4/5 min-h-[50vh] max-[1199px]:p-3 lg:my-10"
    >
      <!-- name, phone number, email -->
      <div v-if="currentPage === 0" class="text-sm w-full sm:w-1/2">
        <router-link
          to="/mm/assessment"
          class="text-slate-300 text-xs hover:text-slate-500"
          ><font-awesome-icon
            icon="fa-solid fa-angle-left"
            class="mr-1"
          />Буцах</router-link
        >
        <h1 class="uppercase font-semibold text-blue-500 text-xl mt-2">
          Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
        </h1>
        <p class="text-xs text-slate-700 mb-5">
          Та доорх хэсэгт үнэн зөв, бүрэн хариулна уу.
        </p>

        <div
          class="flex items-center border rounded w-full text-slate-500 max-sm:placeholder:text-xs"
          :class="
            isInvalid('name') ? 'border-red-500' : 'border-slate-300 mb-4'
          "
        >
          <font-awesome-icon
            icon="fa-solid fa-briefcase"
            class="text-base mr-3 pl-3"
          />
          <input
            id="name"
            name="name"
            type="text"
            v-model="form.name"
            placeholder="Байгууллагын нэр"
            @blur="onBlur('name')"
            class="w-full p-3 rounded"
          />
        </div>
        <p v-if="isInvalid('name')" class="text-red-500 text-xs mb-4">
          Та байгууллагын нэрээ оруулна уу.
        </p>

        <div
          class="flex items-center border rounded w-full text-slate-500 max-sm:placeholder:text-xs"
          :class="
            isInvalid('email') || (isInvalidEmail && form.email)
              ? 'border-red-500'
              : 'border-slate-300 mb-4'
          "
        >
          <font-awesome-icon
            icon="fa-solid fa-envelope"
            class="text-base mr-3 pl-3"
          />
          <input
            id="email"
            name="email"
            type="email"
            v-model="form.email"
            placeholder="И-мэйл хаяг"
            @blur="onBlur('email')"
            class="w-full p-3 rounded"
          />
        </div>
        <p v-if="isInvalid('email')" class="text-red-500 text-xs mb-4">
          Та и-мэйл хаягаа оруулна уу.
        </p>
        <p
          v-if="isInvalidEmail && form.email"
          class="text-red-500 text-xs mb-4"
        >
          И-мэйл хаяг буруу байна
        </p>

        <div
          class="flex items-center border rounded w-full text-slate-500 max-sm:placeholder:text-xs"
          :class="
            isInvalid('phone_number')
              ? 'border-red-500'
              : 'border-slate-300 mb-4'
          "
        >
          <font-awesome-icon
            icon="fa-solid fa-phone"
            class="text-base mr-3 pl-3"
          />
          <input
            id="phone_number"
            name="phone_number"
            type="tel"
            v-model="form.phone_number"
            placeholder="Утасны дугаар"
            @blur="onBlur('phone_number')"
            class="w-full p-3 rounded"
          />
        </div>
        <p v-if="isInvalid('phone_number')" class="text-red-500 text-xs mb-4">
          Та утасны дугаараа оруулна уу.
        </p>

        <button
          @click="startTest()"
          :disabled="isFormInvalid"
          class="text-white p-2 rounded-lg bg-blue-500 enabled:hover:bg-blue-600 disabled:cursor-not-allowed"
        >
          Эхлэх
        </button>
      </div>

      <!-- test -->
      <div v-else-if="currentPage <= totalPages" class="mt-5">
        <table v-if="currentItem" class="w-full">
          <thead class="bg-slate-300 text-black">
            <tr>
              <th class="border border-slate-300">
                {{ currentItem.mainIndex }}
              </th>
              <th class="border border-slate-300 uppercase">
                {{ currentItem.mainTitle }}
              </th>
              <th class="border border-slate-300">Тийм (1)</th>
              <th class="border border-slate-300">Үгүй (0)</th>
              <th class="border border-slate-300">
                Нотлох эх үүсвэр байгаа эсэх
              </th>
            </tr>
          </thead>

          <tbody>
            <template
              v-for="(question, qIndex) in currentItem.questions"
              :key="qIndex"
            >
              <!-- Parent Question -->
              <tr class="text-sm bg-slate-100">
                <td class="p-2 border border-slate-300">
                  {{ question.cid }}
                </td>
                <td class="p-2 border border-slate-300">
                  {{ question.question }}
                </td>
                <td class="border border-slate-300"></td>
                <td class="border border-slate-300"></td>
                <td class="border border-slate-300"></td>
              </tr>

              <!-- Child Questions -->
              <tr
                v-for="(childQuestion, cIndex) in question.children"
                :key="cIndex"
                class="text-sm"
              >
                <td class="p-2 border border-slate-300">
                  {{ childQuestion.cid }}
                </td>
                <td
                  class="p-2 border border-slate-300"
                  :class="
                    childQuestion.isUnanswered ? 'bg-red-200' : 'bg-transparent'
                  "
                >
                  {{ childQuestion.question }}
                </td>
                <td class="border border-slate-300">
                  <div class="flex justify-center items-center">
                    <input
                      type="radio"
                      :name="childQuestion.cid"
                      :value="1"
                      v-model="childQuestion.answer"
                      @change="updateAnswerStatus(childQuestion)"
                    />
                  </div>
                </td>
                <td class="border border-slate-300">
                  <div class="flex justify-center items-center">
                    <input
                      type="radio"
                      :name="childQuestion.cid"
                      :value="0"
                      v-model="childQuestion.answer"
                      @change="updateAnswerStatus(childQuestion)"
                    />
                  </div>
                </td>
                <td class="border border-slate-300">
                  <div class="flex justify-center items-center">
                    <input
                      type="checkbox"
                      :name="childQuestion.cid"
                      v-model="childQuestion.proof"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <p v-if="errorMessage" class="text-center text-red-500 text-xs mt-5">
          {{ errorMessage }}
        </p>

        <div
          class="w-full flex items-center mt-5"
          :class="
            currentPage === totalPages ? 'justify-between' : 'justify-center'
          "
        >
          <PaginationCartTwo
            :modelValue="currentPage"
            :totalPages="totalPages"
            @page-changed="handlePageChanged"
            @request-next-page="handleNextPageRequest"
          />

          <button
            v-if="currentPage === totalPages"
            @click="submitAnswers"
            class="bg-blue-500 p-3 text-white text-sm rounded-lg hover:scale-110"
          >
            Дуусгах
          </button>
        </div>
      </div>

      <div
        v-else-if="
          currentPage > totalPages && resultPercentage !== null && isLoading
        "
        class="flex flex-col justify-center items-center w-full min-h-[40vh]"
      >
        <SpinLoading :color="'rgb(59 130 246)'" />
      </div>

      <div
        v-else-if="
          currentPage > totalPages && resultPercentage !== null && !isLoading
        "
        class="relative flex justify-center items-center w-full"
      >
        <img
          src="@/assets/images/miningMongolia/codex_poster.jpg"
          alt="poster"
          class="w-full md:w-2/3 lg:w-1/2 border mt-5"
        />

        <div
          class="absolute inset-0 flex flex-col justify-center items-center w-full h-full mt-12"
        >
          <h1
            class="font-bold uppercase text-sm min-[400px]:text-base min-[500px]:text-2xl min-[1100px]:text-3xl text-center"
          >
            Хариуцлагатай <br />
            уул уурхайн кодекс
          </h1>
          <p
            v-if="form.name"
            class="text-blue-500 text-base md:text-xl font-bold mt-3 min-[1100px]:mt-5 uppercase"
          >
            {{ form.name ? form.name : null }}
          </p>
          <h6
            class="hidden min-[500px]:flex font-bold uppercase mt-3 min-[1100px]:mt-5"
          >
            өөрийн үнэлгээ
          </h6>
          <div
            class="w-28 h-28 min-[500px]:w-44 min-[500px]:h-44 lg:w-52 lg:h-52"
          >
            <svg viewBox="0 0 36 36" class="circular-chart">
              <path
                class="circle-bg"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                class="circle"
                :stroke-dasharray="`${resultPercentage}, 100`"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" class="percentage">
                {{ `${resultPercentage}%` }}
              </text>
            </svg>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import PaginationCartTwo from "@/components/ui/PaginationCartTwo.vue";
import clientInstance from "@/lib/clientInstance";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMSelfAssessment",
  components: {
    PaginationCartTwo,
    SpinLoading,
  },
  setup() {
    //FORM
    const form = ref({
      name: null,
      email: null,
      phone_number: null,
    });

    const touched = ref({
      name: false,
      email: false,
      phone_number: false,
    });

    const onBlur = (field) => {
      touched.value[field] = true;
    };

    const isInvalid = (field) => {
      return touched.value[field] && !form.value[field];
    };

    const isInvalidEmail = ref(false);
    watch(
      () => form.value.email,
      (newEmail) => {
        if (newEmail) {
          const emailPattern =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          isInvalidEmail.value = !emailPattern.test(newEmail);
        } else {
          isInvalidEmail.value = false;
        }
      },
      { immediate: true }
    );

    const isFormInvalid = computed(() => {
      return (
        isInvalid("name") ||
        isInvalid("email") ||
        isInvalidEmail.value ||
        isInvalid("phone_number")
      );
    });

    const updateAnswerStatus = (childQuestion) => {
      childQuestion.isUnanswered =
        childQuestion.answer === undefined || childQuestion.answer === null;
    };

    const startTest = () => {
      touched.value.name = true;
      touched.value.email = true;
      touched.value.phone_number = true;

      if (!form.value.name || !form.value.email || !form.value.phone_number) {
        return;
      }

      currentPage.value++;
    };

    //ASSESSMENT TEST
    const assessQuestions = ref([]);
    const currentPage = ref(0);
    const totalPages = ref(1);
    const errorMessage = ref(null);
    const isLoading = ref(false);

    const fetchAssessmentQuestions = async () => {
      try {
        const res = await clientInstance.get("/get/assessment");
        assessQuestions.value = res.data.result;
        totalPages.value = assessQuestions.value.length;
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchAssessmentQuestions();
    });

    const currentItem = computed(() => {
      return assessQuestions.value[currentPage.value - 1];
    });

    const handlePageChanged = (page) => {
      currentPage.value = page;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleNextPageRequest = (newPage) => {
      const currentQuestions = currentItem.value.questions;
      let allQuestionsAnswered = true;

      currentQuestions.forEach((question) => {
        question.children.forEach((child) => {
          if (child.answer === undefined || child.answer === null) {
            child.isUnanswered = true;
            allQuestionsAnswered = false;
            errorMessage.value = "Бүх асуултанд хариулна уу.";
            setTimeout(() => {
              errorMessage.value = null;
            }, 5000);
          } else {
            child.isUnanswered = false;
          }
        });
      });

      if (!allQuestionsAnswered) {
        return;
      }

      currentPage.value = newPage;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // SAVE ANSWERS
    const resultPercentage = ref(null);
    const trueCount = ref(0);
    const falseCount = ref(0);

    const submitAnswers = async () => {
      try {
        isLoading.value = true;

        const payload = {
          name: form.value.name,
          email: form.value.email,
          phone_number: form.value.phone_number,
          answers: [],
        };

        let totalQuestions = 0;
        let localTrueCount = 0;
        let localFalseCount = 0;

        assessQuestions.value.forEach((question) => {
          question.questions.forEach((q) => {
            q.children.forEach((child) => {
              payload.answers.push({
                question_id: child.id,
                answer: child.answer,
                proof: child.proof || false,
              });
              totalQuestions++;
              if (child.answer === 1) {
                localTrueCount++;
              } else if (child.answer === 0) {
                localFalseCount++;
              }
            });
          });
        });

        const res = await clientInstance.post("/insert/answer", payload);
        if (res.status === 200) {
          currentPage.value++;
          const truePercentage = Math.round(
            (localTrueCount / totalQuestions) * 100
          );
          resultPercentage.value = truePercentage;
          trueCount.value = localTrueCount;
          falseCount.value = localFalseCount;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    return {
      currentPage,
      totalPages,
      form,
      isFormInvalid,
      startTest,
      handlePageChanged,
      handleNextPageRequest,
      submitAnswers,
      onBlur,
      isInvalid,
      isInvalidEmail,
      assessQuestions,
      currentItem,
      updateAnswerStatus,
      errorMessage,
      isLoading,
      resultPercentage,
      trueCount,
      falseCount,
    };
  },
};
</script>

<style scoped>
.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #4bb543;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

.percentage {
  fill: #4bb543;
  font-size: 0.5em;
  text-anchor: middle;
  font-weight: bold;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0, 100;
  }
}
</style>
