<template>
  <div class="flex justify-center">
    <main
      class="flex flex-col items-center w-full min-[1200px]:w-4/5 min-h-[50vh] max-[1199px]:p-3 lg:my-10"
    >
      <h1 class="uppercase font-semibold text-blue-500 text-xl">
        Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
      </h1>

      <div
        class="flex max-sm:flex-col w-full sm:w-1/2 gap-y-5 gap-x-10 mt-5 sm:mt-10"
      >
        <router-link
          to="/mm/member-assessment"
          class="border border-green px-5 py-2 sm:px-10 sm:py-5 rounded text-center text-green hover:bg-green hover:text-white"
          >Гишүүн байгууллага</router-link
        >
        <router-link
          to="/mm/self-assessment"
          class="border border-green px-5 py-2 sm:px-10 sm:py-5 rounded text-center text-green hover:bg-green hover:text-white"
          >Өөрийн сонирхлоор</router-link
        >
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "MMAssessment",
};
</script>
