<template>
  <div v-if="presidents.length > 0" class="text-xs">
    <table class="table-auto w-full border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 px-4 py-2">Үйлдэл</th>
          <th class="border border-gray-300 px-4 py-2">Нэр</th>
          <th class="border border-gray-300 px-4 py-2">Зураг</th>
          <th class="border border-gray-300 px-4 py-2">Ажилласан огноо</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(president, index) in presidents" :key="index">
          <td class="border border-gray-300 px-4 py-2">
            <div class="flex gap-2 justify-center">
              <button
                @click="editPresident(president)"
                class="bg-sky-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-pen" />
              </button>
              <button
                @click="deletePresident(president.id, president.name)"
                class="bg-red-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ president.name }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            <img
              :src="president.profile"
              alt="Profile"
              class="w-20 h-20 object-contain mx-auto"
            />
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ president.years_of_work }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { ref, onMounted } from "vue";

export default {
  name: "MMPresidentTable",
  props: {
    data: Array,
  },
  setup(props, { emit }) {
    const presidents = ref([]);

    onMounted(() => {
      if (props.data) {
        presidents.value = props.data;
      }
    });

    const editPresident = (president) => {
      emit("setMode", "B", president);
    };

    const deletePresident = async (id, name) => {
      if (!window.confirm(`${name}-г устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/about-us?id=${id}`);
        if (res.status === 200) {
          emit("fetchData");
        }
      } catch (err) {
        return err;
      }
    };

    return {
      presidents,
      editPresident,
      deletePresident,
    };
  },
};
</script>
