<template>
  <div v-if="members.length > 0" class="text-xs">
    <table class="table-auto w-full border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 px-4 py-2">Үйлдэл</th>
          <th class="border border-gray-300 px-4 py-2">Нэр</th>
          <th class="border border-gray-300 px-4 py-2">Лого</th>
          <th class="border border-gray-300 px-4 py-2">Вэбсайт</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(member, index) in members" :key="index">
          <td class="border border-gray-300 px-4 py-2">
            <div class="flex gap-2 justify-center">
              <button
                @click="editMember(member)"
                class="bg-sky-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-pen" />
              </button>
              <button
                @click="deleteMember(member.id, member.name)"
                class="bg-red-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ member.name }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            <img
              :src="member.logo"
              alt="Logo"
              class="w-60 h-20 object-contain mx-auto"
            />
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ member.link }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { ref, onMounted } from "vue";

export default {
  name: "MMMainMembers",
  props: {
    data: Array,
  },
  setup(props, { emit }) {
    const members = ref([]);

    onMounted(() => {
      if (props.data) {
        members.value = props.data;
      }
    });

    const editMember = (member) => {
      emit("setMode", "C", member);
    };

    const deleteMember = async (id, name) => {
      if (!window.confirm(`${name}-г устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/membership?id=${id}`);
        if (res.status === 200) {
          emit("fetchData");
        }
      } catch (err) {
        return err;
      }
    };

    return {
      members,
      editMember,
      deleteMember,
    };
  },
};
</script>
