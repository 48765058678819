<template>
  <div class="flex justify-center">
    <main class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-10 h-screen">
      <iframe
        :src="pdfUrl"
        frameborder="0"
        title="МҮУУА-ийн 30 жил"
        class="w-full h-full"
      ></iframe>
    </main>
  </div>
</template>

<script>
export default {
  name: "AssociationThirtyYear",
  setup() {
    const pdfUrl =
      "https://miningmongolia.mn/static/pdf/MNMA_30_anniversary_book.pdf#toolbar=0&navpanes=0&scrollbar=0";
    return {
      pdfUrl,
    };
  },
};
</script>
