<template>
  <main class="flex justify-center">
    <div
      class="w-full min-[1200px]:w-4/5 min-h-[50vh] max-[1200px]:mx-3 py-5 md:py-10"
    >
      <img
        src="https://miningmongolia.mn/static/1721588511679.png"
        alt="future miners"
        class="w-48 object-contain"
      />

      <p class="text-sm text-justify mt-3 tracking-wide">
        Монголын уул уурхай үндэсний ассоциацийн гишүүдийг сар бүр онцолж байна.
      </p>
    </div>
  </main>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MemberOfTheMonth",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>
