<template>
  <div class="px-8 pt-8">
    <!-- LOADING STATE -->
    <div v-if="loading" class="text-gray-500">
      {{ t("loading") }}
    </div>

    <!-- MAIN CONTENT -->
    <div v-else>
      <div class="mb-4 flex space-x-4">
        <button class="px-3 py-1 border rounded" :class="{ 'bg-green text-white': selectedLang === 'mn' }" @click="switchLanguage('mn')">
          {{ t("langMn") }}
        </button>
        <button class="px-3 py-1 border rounded" :class="{ 'bg-green text-white': selectedLang === 'en' }" @click="switchLanguage('en')">
          {{ t("langEn") }}
        </button>
      </div>

      <div class="mb-4 flex space-x-4">
        <label class="inline-flex items-center space-x-2 cursor-pointer">
          <input type="radio" name="viewType" value="alphabet" v-model="selectedView" />
          <span>{{ t("viewByAlphabet") }}</span>
        </label>
        <label class="inline-flex items-center space-x-2 cursor-pointer">
          <input type="radio" name="viewType" value="category" v-model="selectedView" />
          <span>{{ t("viewByCategory") }}</span>
        </label>
      </div>

      <!-- SEARCH INPUT -->
      <div class="mb-4 w-full">
        <input v-model="searchTerm" type="text" :placeholder="placeholderText" class="w-96 border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500" />
      </div>

      <!-- ALPHABET VIEW -->
      <div v-if="selectedView === 'alphabet'">
        <div class="mb-4 flex flex-wrap gap-2">
          <button v-for="(letter, idx) in alphabetToUse" :key="idx" class="border px-2 py-1 rounded" :class="selectedLetter === letter ? 'bg-blue-200' : ''" @click="selectedLetter = letter">
            {{ letter }}
          </button>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <div v-for="(co, index) in filteredCompaniesByLetter" :key="index" class="border rounded p-4 bg-white">
            <h3 class="font-semibold text-lg mb-2">
              {{ co.company_name || t("noName") }}
            </h3>

            <p v-if="co.company_location" class="text-sm">
              <strong>{{ t("locationLabel") }}:</strong>
              {{ co.company_location }}
            </p>

            <p v-if="co.company_address" class="text-sm">
              <strong>{{ t("addressLabel") }}:</strong>
              {{ co.company_address }}
            </p>

            <p v-if="co.phones && co.phones.length" class="text-sm">
              <strong>{{ t("phoneLabel") }}:</strong>
              {{ co.phones.join(", ") }}
            </p>

            <p v-if="co.faxes && co.faxes.length" class="text-sm">
              <strong>{{ t("faxLabel") }}:</strong>
              {{ co.faxes.join(", ") }}
            </p>

            <p v-if="co.emails && co.emails.length" class="text-sm">
              <strong>{{ t("emailLabel") }}:</strong>
              {{ co.emails.join(", ") }}
            </p>

            <p v-if="co.websites && co.websites.length" class="text-sm">
              <strong>{{ t("webLabel") }}:</strong>
              {{ co.websites.join(", ") }}
            </p>
          </div>

          <div v-if="filteredCompaniesByLetter.length === 0" class="text-gray-500">
            {{ t("noMatchingCompanies") }}
          </div>
        </div>
      </div>

      <!-- CATEGORY VIEW -->
      <div v-else>
        <div class="flex">
          <div class="w-1/4 pr-4 border-r sticky top-0 h-screen overflow-y-auto" style="align-self: flex-start">
            <div
              v-for="(cat, idx) in filteredCategories"
              :key="cat.category_id"
              class="p-4 mb-2 rounded cursor-pointer"
              :class="{
                'text-white bg-green': selectedCategoryIndex === idx,
              }"
              @click="selectCategory(idx)"
            >
              <h2 class="text-lg font-bold mb-1">
                {{ cat.category_name || t("noCategoryName") }}
              </h2>
              <p
                class="text-xs"
                :class="{
                  'text-white': selectedCategoryIndex === idx,
                  'text-gray-500': selectedCategoryIndex !== idx,
                }"
              >
                {{ cat.category_code }}
              </p>
            </div>
          </div>

          <!-- RIGHT PANEL -->
          <div class="flex-1 pl-4">
            <div v-if="selectedCategoryIndex === null || !filteredCategories.length" class="text-gray-500">
              {{ t("noCategoryName") }}
            </div>

            <div v-else>
              <transition name="fade">
                <div key="cat-details" class="overflow-auto pb-16">
                  <div v-if="selectedCategory.category_code === '0403'">
                    <h3 class="text-xl font-semibold mb-4">{{ selectedCategory.category_name }} ({{ t("peopleLabel") }})</h3>
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                      <div v-for="(person, pIndex) in selectedCategory.people" :key="pIndex" class="border rounded p-4 bg-white">
                        <p class="font-bold text-md mb-2">
                          {{ person.person_name }}
                        </p>

                        <p v-if="person.person_profession" class="text-sm">
                          <strong>{{ t("professionLabel") }}:</strong>
                          {{ person.person_profession }}
                        </p>

                        <p v-if="person.person_advice_range" class="text-sm">
                          <strong>{{ t("adviceLabel") }}:</strong>
                          {{ person.person_advice_range }}
                        </p>

                        <p v-if="person.person_phone" class="text-sm">
                          <strong>{{ t("phoneLabel") }}:</strong>
                          {{ person.person_phone }}
                        </p>
                      </div>

                      <div v-if="!selectedCategory.people.length" class="text-gray-500 col-span-full">
                        {{ t("noPeopleFound") }}
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <h3 class="text-xl font-semibold mb-4">{{ selectedCategory.category_name }}</h3>
                    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                      <div v-for="(co, cIndex) in selectedCategory.companies" :key="cIndex" class="border rounded p-4 bg-white">
                        <p class="font-bold text-md mb-2">
                          {{ co.company_name }}
                        </p>

                        <p v-if="co.company_location" class="text-sm">
                          <strong>{{ t("locationLabel") }}:</strong>
                          {{ co.company_location }}
                        </p>

                        <p v-if="co.company_address" class="text-sm">
                          <strong>{{ t("addressLabel") }}:</strong>
                          {{ co.company_address }}
                        </p>

                        <p v-if="co.phones && co.phones.length" class="text-sm">
                          <strong>{{ t("phoneLabel") }}:</strong>
                          {{ co.phones.join(", ") }}
                        </p>

                        <p v-if="co.faxes && co.faxes.length" class="text-sm">
                          <strong>{{ t("faxLabel") }}:</strong>
                          {{ co.faxes.join(", ") }}
                        </p>

                        <p v-if="co.emails && co.emails.length" class="text-sm">
                          <strong>{{ t("emailLabel") }}:</strong>
                          {{ co.emails.join(", ") }}
                        </p>

                        <p v-if="co.websites && co.websites.length" class="text-sm">
                          <strong>{{ t("webLabel") }}:</strong>
                          {{ co.websites.join(", ") }}
                        </p>
                      </div>

                      <div v-if="!selectedCategory.companies.length" class="text-gray-500 col-span-full">
                        {{ t("noCompaniesFound") }}
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "CategoriesMixed",
  setup() {
    // --------------------------------------------------
    // TRANSLATIONS
    // --------------------------------------------------
    const translations = ref({
      mn: {
        loading: "Уншиж байна...",
        langMn: "Монгол (MN)",
        langEn: "Англи (EN)",
        viewByAlphabet: "Цагаан толгойгоор харах",
        viewByCategory: "Категориор харах",
        searchPlaceholder: "Хайх (нэр, утас, имэйл, вэб)",
        noName: "Нэр алга",
        locationLabel: "Байршил",
        addressLabel: "Хаяг",
        phoneLabel: "Утас",
        faxLabel: "Факс",
        emailLabel: "И-мэйл",
        webLabel: "Вэб",
        noMatchingCompanies: "Компани олдсонгүй.",
        noCategoryName: "Аль нэг категори сонгоно уу",
        peopleLabel: "Зөвлөх инженер",
        professionLabel: "Мэргэжил",
        adviceLabel: "Зөвлөгөө",
        noPeopleFound: "Энэ категорид хүн алга.",
        companiesLabel: "Компаниуд",
        noCompaniesFound: "Энэ категорид компани алга.",
      },
      en: {
        loading: "Loading...",
        langMn: "Mongolian (MN)",
        langEn: "English (EN)",
        viewByAlphabet: "Display by Alphabet",
        viewByCategory: "Display by Category",
        searchPlaceholder: "Search (name, phone, email, web)",
        noName: "No name",
        locationLabel: "Location",
        addressLabel: "Address",
        phoneLabel: "Phone",
        faxLabel: "Fax",
        emailLabel: "Email",
        webLabel: "Web",
        noMatchingCompanies: "No matching companies.",
        noCategoryName: "Please select a category",
        peopleLabel: "Consultant Engineer",
        professionLabel: "Profession",
        adviceLabel: "Advice",
        noPeopleFound: "No people found in this category.",
        companiesLabel: "Companies",
        noCompaniesFound: "No companies found in this category.",
      },
    });

    // --------------------------------------------------
    // STATE
    // --------------------------------------------------
    const loading = ref(true);
    const searchTerm = ref("");

    const savedLang = localStorage.getItem("selectedLang");
    const selectedLang = ref(savedLang === "mn" || savedLang === "en" ? savedLang : "mn");

    const selectedView = ref("alphabet");

    const alphMn = ["А", "Б", "В", "Г", "Д", "Е", "Ж", "З", "И", "К", "Л", "М", "Н", "О", "Ө", "П", "Р", "С", "Т", "У", "Ү", "Ф", "Х", "Ц", "Ч", "Ш", "Щ", "Ь", "Ы", "Э", "Ю", "Я"];
    const alphEn = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

    const selectedLetter = ref(selectedLang.value === "en" ? alphEn[0] : alphMn[0]);

    const categories = ref([]);
    const selectedCategoryIndex = ref(null);

    // --------------------------------------------------
    // FETCH
    // --------------------------------------------------
    const fetchCategories = async () => {
      loading.value = true;
      try {
        const response = await adminInstance.get(`/yellow-page/list?lang=${selectedLang.value}`);
        categories.value = response.data.map((cat) => ({
          ...cat,
          isOpen: false,
        }));
      } catch (err) {
        console.error("Error fetching categories:", err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchCategories();
    });

    watch(selectedLang, (newLang) => {
      localStorage.setItem("selectedLang", newLang);
      fetchCategories();
      selectedLetter.value = newLang === "mn" ? alphMn[0] : alphEn[0];
      selectedCategoryIndex.value = null;
    });

    const switchLanguage = (lang) => {
      selectedLang.value = lang;
    };

    // --------------------------------------------------
    // COMPUTEDS
    // --------------------------------------------------
    const t = (key) => translations.value[selectedLang.value][key] || key;

    const placeholderText = computed(() => {
      return translations.value[selectedLang.value].searchPlaceholder;
    });

    const alphabetToUse = computed(() => {
      return selectedLang.value === "mn" ? alphMn : alphEn;
    });

    const filteredCategories = computed(() => {
      const term = searchTerm.value.trim().toLowerCase();
      if (!term) return categories.value;

      return categories.value
        .map((cat) => {
          if (cat.category_code === "0403") {
            const filteredPeople = cat.people.filter((p) => {
              return (
                p.person_name.toLowerCase().includes(term) ||
                p.person_phone.toLowerCase().includes(term) ||
                p.person_profession.toLowerCase().includes(term) ||
                p.person_advice_range.toLowerCase().includes(term)
              );
            });
            return { ...cat, people: filteredPeople };
          } else {
            const filteredCompanies = cat.companies.filter((co) => {
              const phoneStr = co.phones.join(", ").toLowerCase();
              const faxStr = co.faxes.join(", ").toLowerCase();
              const emailStr = co.emails.join(", ").toLowerCase();
              const webStr = co.websites.join(", ").toLowerCase();
              const nameStr = co.company_name.toLowerCase();
              const locStr = co.company_location.toLowerCase();
              const addrStr = co.company_address.toLowerCase();

              return nameStr.includes(term) || phoneStr.includes(term) || faxStr.includes(term) || emailStr.includes(term) || webStr.includes(term) || locStr.includes(term) || addrStr.includes(term);
            });
            return { ...cat, companies: filteredCompanies };
          }
        })
        .filter((cat) => {
          if (cat.category_code === "0403") {
            return cat.people.length > 0;
          }
          return cat.companies.length > 0;
        });
    });

    const selectedCategory = computed(() => {
      if (selectedCategoryIndex.value === null || !filteredCategories.value[selectedCategoryIndex.value]) {
        return null;
      }
      return filteredCategories.value[selectedCategoryIndex.value];
    });

    const filteredCompaniesByLetter = computed(() => {
      const letter = selectedLetter.value.toLowerCase();
      const term = searchTerm.value.trim().toLowerCase();

      const allCompanies = [];
      for (const cat of categories.value) {
        if (cat.category_code !== "0403") {
          allCompanies.push(...cat.companies);
        }
      }
      return allCompanies.filter((co) => {
        const nameStr = co.company_name.toLowerCase();
        if (!nameStr.startsWith(letter)) return false;

        if (!term) return true;
        const phoneStr = co.phones.join(", ").toLowerCase();
        const faxStr = co.faxes.join(", ").toLowerCase();
        const emailStr = co.emails.join(", ").toLowerCase();
        const webStr = co.websites.join(", ").toLowerCase();
        const locStr = co.company_location.toLowerCase();
        const addrStr = co.company_address.toLowerCase();

        return nameStr.includes(term) || phoneStr.includes(term) || faxStr.includes(term) || emailStr.includes(term) || webStr.includes(term) || locStr.includes(term) || addrStr.includes(term);
      });
    });

    // --------------------------------------------------
    // METHODS
    // --------------------------------------------------
    const selectCategory = (idx) => {
      if (idx === selectedCategoryIndex.value) {
        return;
      }
      selectedCategoryIndex.value = idx;
    };

    const toggleCategory = (catIndex) => {
      filteredCategories.value[catIndex].isOpen = !filteredCategories.value[catIndex].isOpen;
    };

    watch(filteredCategories, (newList) => {
      if (selectedView.value === "category") {
        if (selectedCategoryIndex.value === null && newList.length > 0) {
          selectedCategoryIndex.value = 0;
        }
        if (newList.length === 0) {
          selectedCategoryIndex.value = null;
        }
      }
    });

    // --------------------------------------------------
    // RETURN
    // --------------------------------------------------
    return {
      loading,
      searchTerm,
      selectedLang,
      selectedView,
      selectedLetter,
      selectedCategoryIndex,
      categories,

      t,
      placeholderText,
      alphabetToUse,
      filteredCategories,
      selectedCategory,
      filteredCompaniesByLetter,

      fetchCategories,
      switchLanguage,
      toggleCategory,
      selectCategory,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #666;
}
</style>
