<template>
  <admin-navbar>
    <main v-if="isMode === 'A'" class="space-y-5">
      <!-- boardMembers -->
      <h1 class="text-lg font-bold">Зөвлөлийн гишүүд</h1>

      <div class="bg-white rounded p-3 h-[60vh] overflow-y-scroll">
        <button
          @click="setMode('B')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Гишүүн нэмэх
        </button>

        <MMBoardMembers
          v-if="boardMembers.length > 0 && !isLoading"
          :data="boardMembers"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="boardMembers.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>

      <!-- Council -->
      <h1 class="text-lg font-bold">Үндсэн гишүүд</h1>

      <div class="bg-white rounded p-3 h-[60vh] overflow-y-scroll">
        <button
          @click="setMode('C')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Гишүүн нэмэх
        </button>

        <MMMainMembers
          v-if="mainMembers.length > 0 && !isLoading"
          :data="mainMembers"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="mainMembers.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>
    </main>

    <main v-else>
      <MMEditBoardMem
        v-if="isMode === 'B'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />

      <MMEditMainMem
        v-else-if="isMode === 'C'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />
    </main>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref } from "vue";
import MMBoardMembers from "@/containers/MiningMongolia/admin/Membership/MMBoardMembers.vue";
import MMMainMembers from "@/containers/MiningMongolia/admin/Membership/MMMainMembers.vue";
import MMEditBoardMem from "@/containers/MiningMongolia/admin/Membership/MMEditBoardMem.vue";
import MMEditMainMem from "@/containers/MiningMongolia/admin/Membership/MMEditMainMem.vue";

export default {
  name: "MMAdminAbout",
  components: {
    AdminNavbar,
    SpinLoading,
    MMBoardMembers,
    MMMainMembers,
    MMEditBoardMem,
    MMEditMainMem,
  },
  setup() {
    const isMode = ref("A");
    const selectedData = ref(null);
    const switchMode = (mode, data) => {
      isMode.value = mode;
      selectedData.value = data;
    };

    const boardMembers = ref([]);
    const mainMembers = ref([]);
    const isLoading = ref(false);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/membership");
        if (res.status === 200) {
          boardMembers.value = res.data.logos.filter(
            (item) => item.board_member
          );
          mainMembers.value = res.data.logos.filter((item) => item.main_member);
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const setMode = (mode) => {
      isMode.value = mode;
      if (mode === "A") {
        selectedData.value = null;
        fetchData();
      }
    };

    return {
      isMode,
      switchMode,
      boardMembers,
      mainMembers,
      isLoading,
      selectedData,
      setMode,
      fetchData,
    };
  },
};
</script>
