<template>
  <main class="flex justify-center">
    <div class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-5 md:py-10">
      <img
        v-if="lang === 'mn'"
        src="../../../assets/images/miningMongolia/home-pages-cards/bidnii medehgui uul uurhai logo-01.png"
        alt="responsible mining"
        class="w-60 h-20 object-contain"
      />
      <img
        v-else
        src="../../../assets/images/miningMongolia/home-pages-cards/bidnii medehgui uul uurhai logo-02.png"
        alt="responsible mining"
        class="w-60 h-20 object-contain"
      />

      <p class="text-sm text-justify mt-3 tracking-wide">
        {{
          lang === "mn"
            ? "In order to spread the right knowledge and information about the mineral resources sector to the public, the “The Mining that we don't know about” project has been initiated and implemented from March 2023. Within the framework of this project, interesting facts, figures, and research about the types of minerals are published and distributed through social channels, aiming to improve the basic knowledge of the people about the industry."
            : "In order to spread the right knowledge and information about the mineral resources sector to the public, the “The Mining that we don't know about” project has been initiated and implemented from March 2023. Within the framework of this project, interesting facts, figures, and research about the types of minerals are published and distributed through social channels, aiming to improve the basic knowledge of the people about the industry."
        }}
      </p>

      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-5 mt-10"
      >
        <img
          v-for="mineral in minerals"
          :key="mineral.id"
          :src="mineral.image"
          alt="mineral"
          class="w-full"
        />
      </div>
    </div>
  </main>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MiningWeDontKnow",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const minerals = ref([]);
    const fetchMinerals = async () => {
      try {
        const res = await clientInstance.get("/get/minerals");
        if (res.status === 200) minerals.value = res.data.minerals;
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchMinerals();
    });

    return { lang, minerals };
  },
};
</script>
