<template>
  <admin-navbar>
    <main v-if="isMode === 'A'" class="space-y-5">
      <!-- Presidents -->
      <h1 class="text-lg font-bold">Хүндэт ерөнхийлөгчид</h1>

      <div class="bg-white rounded p-3 h-[60vh] overflow-y-scroll">
        <button
          @click="setMode('B')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Ерөнхийлөгч нэмэх
        </button>

        <MMPresidentTable
          v-if="presidents.length > 0 && !isLoading"
          :data="presidents"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="presidents.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>

      <!-- Council -->
      <h1 class="text-lg font-bold">Ассоциацийн зөвлөл</h1>

      <div class="bg-white rounded p-3 h-[60vh] overflow-y-scroll">
        <button
          @click="setMode('C')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Зөвлөлийн гишүүн нэмэх
        </button>

        <MMCouncilTable
          v-if="councils.length > 0 && !isLoading"
          :data="councils"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="councils.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>

      <!-- Office -->
      <h1 class="text-lg font-bold">Ажлын алба</h1>

      <div class="bg-white rounded p-3 h-[60vh] overflow-y-scroll">
        <button
          @click="setMode('D')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Ажлын алба нэмэх
        </button>

        <MMOfficeTable
          v-if="workers.length > 0 && !isLoading"
          :data="workers"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="workers.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>
    </main>

    <main v-else>
      <MMEditPresident
        v-if="isMode === 'B'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />

      <MMEditCouncil
        v-else-if="isMode === 'C'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />

      <MMEditOffice
        v-else-if="isMode === 'D'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />
    </main>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref } from "vue";
import MMPresidentTable from "@/containers/MiningMongolia/admin/AboutUs/MMPresidentTable.vue";
import MMCouncilTable from "@/containers/MiningMongolia/admin/AboutUs/MMCouncilTable.vue";
import MMOfficeTable from "@/containers/MiningMongolia/admin/AboutUs/MMOfficeTable.vue";
import MMEditPresident from "@/containers/MiningMongolia/admin/AboutUs/MMEditPresident.vue";
import MMEditCouncil from "@/containers/MiningMongolia/admin/AboutUs/MMEditCouncil.vue";
import MMEditOffice from "@/containers/MiningMongolia/admin/AboutUs/MMEditOffice.vue";

export default {
  name: "MMAdminAbout",
  components: {
    AdminNavbar,
    MMPresidentTable,
    MMCouncilTable,
    SpinLoading,
    MMOfficeTable,
    MMEditPresident,
    MMEditCouncil,
    MMEditOffice,
  },
  setup() {
    const isMode = ref("A");
    const selectedData = ref(null);
    const switchMode = (mode, data) => {
      isMode.value = mode;
      selectedData.value = data;
    };

    const presidents = ref([]);
    const councils = ref([]);
    const workers = ref([]);
    const isLoading = ref(false);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/about-us");
        if (res.status === 200) {
          presidents.value = res.data.about.filter(
            (item) => item.about_category === "President"
          );
          councils.value = res.data.about.filter(
            (item) => item.about_category === "Association"
          );
          workers.value = res.data.about.filter(
            (item) => item.about_category === "Office"
          );
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const setMode = (mode) => {
      isMode.value = mode;
      if (mode === "A") {
        selectedData.value = null;
        fetchData();
      }
    };

    return {
      isMode,
      switchMode,
      presidents,
      councils,
      workers,
      isLoading,
      selectedData,
      setMode,
      fetchData,
    };
  },
};
</script>
