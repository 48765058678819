<template>
  <div class="p-4">
    <div v-if="loading" class="text-gray-500">Loading...</div>

    <div v-else>
      <div class="mb-4">
        <p class="text-gray-700">Нийт категори: {{ categories.length }}</p>
        <p class="text-gray-700">Нийт rows: {{ totalRows }}</p>
      </div>

      <div class="mb-4 w-full">
        <input
          v-model="searchTerm"
          type="text"
          placeholder="Хайх (нэр, утас, имэйл, вэб, мэргэжил...)"
          class="w-64 border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-1 focus:ring-blue-500"
        />
      </div>

      <div v-if="searchTerm && flattenedSearchResults.length" class="mb-6">
        <h2 class="text-lg font-bold text-gray-800 mb-2">Илэрц</h2>
        <div class="overflow-x-auto border rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Категори</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Код</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Type</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Нэр</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Мэргэжил</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Зөвлөгөө</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Утас</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Байршил</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Хаяг</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Факс</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">И-мэйл</th>
                <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Вэб</th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(res, idx) in flattenedSearchResults" :key="idx">
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.category_name }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.category_code }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.type }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.name }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.profession }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.adviceRange }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.phone }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.location }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.address }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.faxes }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.emails }}
                </td>
                <td class="px-4 py-2 text-sm text-gray-700">
                  {{ res.websites }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-for="(cat, catIndex) in filteredCategories" :key="cat.category_id" class="mb-8">
        <div class="mb-2 flex items-center cursor-pointer select-none" @click="toggleCategory(catIndex)">
          <h2 class="text-xl font-bold text-gray-800 mr-2">
            {{ cat.category_name || "Категорийн нэр алга" }}
            <span class="text-base text-gray-600 ml-2"> ({{ cat.category_code === "0403" ? cat.people.length : cat.companies.length }} {{ cat.category_code === "0403" ? "хүн" : "компани" }}) </span>
          </h2>
        </div>

        <div class="text-sm text-gray-500 mb-2">
          Категорийн код:
          <span class="font-medium">{{ cat.category_code }}</span>
          | Категорийн дугаар:
          <span class="font-medium">{{ cat.category_id }}</span>
        </div>

        <transition name="fade">
          <div v-if="cat.isOpen" class="overflow-x-auto border rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr v-if="cat.category_code === '0403'">
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Нэр</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">External ID</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Мэргэжил</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Зөвлөгөө</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Утас</th>
                </tr>
                <tr v-else>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Компанийн нэр</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">External ID</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Байршил</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Хаяг</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Утас</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Факс</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">И-мэйл</th>
                  <th class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Вэб</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-if="cat.category_code === '0403'">
                  <tr v-for="person in cat.people" :key="person.person_id">
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_name || "Нэр алга" }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_external_id }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_id }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_profession }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_advice_range }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ person.person_phone }}
                    </td>
                  </tr>
                  <tr v-if="!cat.people.length">
                    <td class="px-4 py-2 text-sm text-gray-400 text-center" colspan="6">Энэ категорид хүн алга.</td>
                  </tr>
                </template>

                <template v-else>
                  <tr v-for="co in cat.companies" :key="co.company_id">
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.company_name || "Нэр алга" }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.company_external_id }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.company_id }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.company_location }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.company_address }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.phones.join(", ") }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.faxes.join(", ") }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.emails.join(", ") }}
                    </td>
                    <td class="px-4 py-2 text-sm text-gray-700">
                      {{ co.websites.join(", ") }}
                    </td>
                  </tr>
                  <tr v-if="!cat.companies.length">
                    <td class="px-4 py-2 text-sm text-gray-400 text-center" colspan="9">Энэ категорид компани алга.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "CategoriesMixed",
  setup() {
    const loading = ref(true);
    const searchTerm = ref("");
    const categories = ref([]);

    const fetchCategories = async () => {
      try {
        const response = await adminInstance.get("/yellow-page/list?lang=mn");

        categories.value = response.data.map((cat) => ({
          ...cat,
          isOpen: false,
        }));
      } catch (err) {
        console.error("Error fetching categories (mixed):", err);
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      fetchCategories();
    });

    const toggleCategory = (index) => {
      filteredCategories.value[index].isOpen = !filteredCategories.value[index].isOpen;
    };

    const filteredCategories = computed(() => {
      const term = searchTerm.value.trim().toLowerCase();
      if (!term) {
        return categories.value;
      }

      return categories.value
        .map((cat) => {
          if (cat.category_code === "0403") {
            const filteredPeople = cat.people.filter((p) => {
              return (
                p.person_name.toLowerCase().includes(term) ||
                p.person_phone.toLowerCase().includes(term) ||
                p.person_profession.toLowerCase().includes(term) ||
                p.person_advice_range.toLowerCase().includes(term)
              );
            });
            return { ...cat, people: filteredPeople };
          } else {
            const filteredCompanies = cat.companies.filter((co) => {
              const phoneStr = co.phones.join(", ").toLowerCase();
              const faxStr = co.faxes.join(", ").toLowerCase();
              const emailStr = co.emails.join(", ").toLowerCase();
              const webStr = co.websites.join(", ").toLowerCase();
              const nameStr = co.company_name.toLowerCase();
              const locStr = co.company_location.toLowerCase();
              const addrStr = co.company_address.toLowerCase();

              return nameStr.includes(term) || phoneStr.includes(term) || faxStr.includes(term) || emailStr.includes(term) || webStr.includes(term) || locStr.includes(term) || addrStr.includes(term);
            });
            return { ...cat, companies: filteredCompanies };
          }
        })
        .filter((cat) => {
          if (cat.category_code === "0403") {
            return cat.people.length > 0;
          }
          return cat.companies.length > 0;
        });
    });

    const flattenedSearchResults = computed(() => {
      const term = searchTerm.value.trim().toLowerCase();
      if (!term) return [];

      const results = [];
      for (const cat of filteredCategories.value) {
        if (cat.category_code === "0403") {
          for (const p of cat.people) {
            results.push({
              category_name: cat.category_name,
              category_code: cat.category_code,
              type: "person",
              name: p.person_name,
              profession: p.person_profession,
              adviceRange: p.person_advice_range,
              phone: p.person_phone,

              location: "",
              address: "",
              faxes: "",
              emails: "",
              websites: "",
            });
          }
        } else {
          for (const co of cat.companies) {
            results.push({
              category_name: cat.category_name,
              category_code: cat.category_code,
              type: "company",
              name: co.company_name,
              profession: "",
              adviceRange: "",
              phone: co.phones.join(", "),
              location: co.company_location,
              address: co.company_address,
              faxes: co.faxes.join(", "),
              emails: co.emails.join(", "),
              websites: co.websites.join(", "),
            });
          }
        }
      }
      return results;
    });

    const totalRows = computed(() => {
      let sum = 0;
      for (const cat of categories.value) {
        if (cat.category_code === "0403") {
          sum += cat.people.length;
        } else {
          sum += cat.companies.length;
        }
      }
      return sum;
    });

    return {
      loading,
      searchTerm,
      categories,
      toggleCategory,

      filteredCategories,
      flattenedSearchResults,
      totalRows,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
