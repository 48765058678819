<template>
  <div v-if="workers.length > 0" class="text-xs">
    <table class="table-auto w-full border-collapse border border-gray-200">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 px-4 py-2">Үйлдэл</th>
          <th class="border border-gray-300 px-4 py-2">Нэр</th>
          <th class="border border-gray-300 px-4 py-2">Зураг</th>
          <th class="border border-gray-300 px-4 py-2">Албан тушаал</th>
          <th class="border border-gray-300 px-4 py-2">И-мэйл хаяг</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(worker, index) in workers" :key="index">
          <td class="border border-gray-300 px-4 py-2">
            <div class="flex gap-2 justify-center">
              <button
                @click="editWorker(worker)"
                class="bg-sky-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-pen" />
              </button>
              <button
                @click="deleteWorker(worker.id, worker.name)"
                class="bg-red-500 text-white px-2 py-1 rounded"
              >
                <font-awesome-icon icon="fa-solid fa-trash" />
              </button>
            </div>
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ worker.name }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            <img
              :src="worker.profile"
              alt="Profile"
              class="w-20 h-20 object-contain mx-auto"
            />
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ worker.position }}
          </td>
          <td class="border border-gray-300 px-4 py-2 text-center">
            {{ worker.email }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { ref, onMounted } from "vue";

export default {
  name: "MMCouncilTable",
  props: {
    data: Array,
  },
  setup(props, { emit }) {
    const workers = ref([]);

    onMounted(() => {
      if (props.data) {
        workers.value = props.data;
      }
    });

    const editWorker = (worker) => {
      emit("setMode", "D", worker);
    };

    const deleteWorker = async (id, name) => {
      if (!window.confirm(`${name}-г устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/about-us?id=${id}`);
        if (res.status === 200) {
          emit("fetchData");
        }
      } catch (err) {
        return err;
      }
    };

    return {
      workers,
      editWorker,
      deleteWorker,
    };
  },
};
</script>
