<template>
  <admin-navbar>
    <main v-if="isMode === 'A'" class="space-y-5">
      <h1 class="text-lg font-bold">МУУҮА-ийн гүшүүн байгууллагууд</h1>

      <div class="bg-white rounded p-3 h-[80vh] overflow-y-scroll">
        <button
          @click="setMode('B')"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Шинэ гишүүн нэмэх
        </button>

        <MMCompaniesTable
          v-if="companies.length > 0 && !isLoading"
          :data="companies"
          @setMode="switchMode"
          @fetchData="fetchData"
        />

        <div
          v-else-if="companies.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>
    </main>

    <main v-else>
      <MMEditCompanies
        v-if="isMode === 'B'"
        :data="selectedData"
        @cancel-edit="setMode('A')"
      />
    </main>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref } from "vue";
import MMCompaniesTable from "@/containers/MiningMongolia/admin/MMCompaniesTable";
import MMEditCompanies from "@/containers/MiningMongolia/admin/MMEditCompanies";

export default {
  name: "MMMemberCompanies",
  components: {
    AdminNavbar,
    SpinLoading,
    MMCompaniesTable,
    MMEditCompanies,
  },
  setup() {
    const isMode = ref("A");
    const selectedData = ref(null);
    const switchMode = (mode, data) => {
      isMode.value = mode;
      selectedData.value = data;
    };

    const companies = ref([]);
    const isLoading = ref(false);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/get/member-companies");
        if (res.status === 200) {
          companies.value = res.data.members;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const setMode = (mode) => {
      isMode.value = mode;
      if (mode === "A") {
        selectedData.value = null;
        fetchData();
      }
    };

    return {
      isMode,
      switchMode,
      companies,
      isLoading,
      selectedData,
      setMode,
      fetchData,
    };
  },
};
</script>
