<template>
  <div v-if="currentPage <= totalPages" class="mt-5">
    <h1 class="uppercase font-semibold text-blue-500 text-xl text-center mb-3">
      Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
    </h1>

    <table v-if="currentItem" class="w-full">
      <thead class="bg-slate-300 text-black">
        <tr>
          <th class="border border-slate-300">
            {{ currentItem.mainIndex }}
          </th>
          <th class="border border-slate-300 uppercase">
            {{ currentItem.mainTitle }}
          </th>
          <th class="border border-slate-300">Тийм (1)</th>
          <th class="border border-slate-300">Үгүй (0)</th>
          <th class="border border-slate-300">Нотлох эх үүсвэр байгаа эсэх</th>
          <th class="border border-slate-300">Тайлбар</th>
        </tr>
      </thead>

      <tbody>
        <template
          v-for="(question, qIndex) in currentItem.questions"
          :key="qIndex"
        >
          <!-- Parent Question -->
          <tr class="text-sm bg-slate-100">
            <td class="p-2 border border-slate-300">
              {{ question.cid }}
            </td>
            <td class="p-2 border border-slate-300">
              {{ question.question }}
            </td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
          </tr>

          <!-- Child Questions -->
          <tr
            v-for="(childQuestion, cIndex) in question.children"
            :key="cIndex"
            class="text-sm"
          >
            <td class="p-2 border border-slate-300">
              {{ childQuestion.cid }}
            </td>
            <td
              class="p-2 border border-slate-300"
              :class="
                childQuestion.isUnanswered ? 'bg-red-200' : 'bg-transparent'
              "
            >
              {{ childQuestion.question }}
            </td>
            <td class="border border-slate-300">
              <div class="flex justify-center items-center">
                <input
                  type="radio"
                  :name="childQuestion.cid"
                  :value="1"
                  v-model="childQuestion.answer"
                  @change="updateAnswerStatus(childQuestion)"
                />
              </div>
            </td>
            <td class="border border-slate-300">
              <div class="flex justify-center items-center">
                <input
                  type="radio"
                  :name="childQuestion.cid"
                  :value="0"
                  v-model="childQuestion.answer"
                  @change="updateAnswerStatus(childQuestion)"
                />
              </div>
            </td>
            <td class="border border-slate-300">
              <div class="flex flex-col justify-center items-center">
                <input
                  type="checkbox"
                  :name="childQuestion.cid"
                  v-model="childQuestion.proof"
                />
                <input
                  v-if="childQuestion.proof"
                  type="file"
                  name="proof"
                  @change="handleFileUpload($event, childQuestion)"
                  class="m-2 w-32"
                />
              </div>
            </td>

            <td class="border border-slate-300">
              <textarea
                class="w-full p-2 w-32 placeholder:text-sm"
                v-model="childQuestion.comment"
                placeholder="Тайлбар"
              ></textarea>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <p v-if="errorMessage" class="text-center text-red-500 text-xs mt-5">
      {{ errorMessage }}
    </p>

    <div
      class="w-full flex items-center mt-5"
      :class="currentPage === totalPages ? 'justify-between' : 'justify-center'"
    >
      <PaginationCartTwo
        :modelValue="currentPage"
        :totalPages="totalPages"
        @page-changed="handlePageChanged"
        @request-next-page="handleNextPageRequest"
      />

      <button
        v-if="currentPage === totalPages"
        @click="submitAnswers"
        class="bg-blue-500 p-3 text-white text-sm rounded-lg hover:scale-110"
      >
        Дуусгах
      </button>
    </div>
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      isLoading &&
      !isFinished
    "
    class="flex flex-col justify-center items-center w-full min-h-[40vh]"
  >
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      !isLoading &&
      !isFinished
    "
    class="flex justify-center w-full gap-x-10"
  >
    <!-- ZURAG -->
    <div class="relative flex justify-center items-center w-1/2">
      <img
        src="@/assets/images/miningMongolia/codex_poster.jpg"
        alt="poster"
        class="w-full border"
      />

      <div
        class="absolute inset-0 flex flex-col items-center w-full h-full mt-28"
      >
        <h1
          class="font-bold uppercase text-sm min-[400px]:text-base min-[500px]:text-lg min-[1100px]:text-xl text-center"
        >
          Хариуцлагатай <br />
          уул уурхайн кодекс
        </h1>

        <div v-if="member.logo" class="flex justify-center w-full my-3 lg:my-5">
          <img :src="member?.logo" alt="logo" class="w-1/2 object-contain" />
        </div>
        <h6
          class="hidden min-[500px]:flex font-bold uppercase text-sm min-[400px]:text-base min-[500px]:text-lg"
        >
          өөрийн үнэлгээ
        </h6>
        <p class="text-7xl text-[#4bb543] font-bold">
          {{ `${resultPercentage}%` }}
        </p>
      </div>
    </div>

    <!-- FILES -->
    <div class="w-1/2 text-sm">
      <label for="official_document"
        >1. Албан бичиг:<span class="text-red-500 text-sm">*</span></label
      >
      <input
        id="official_document"
        name="official_document"
        type="file"
        class="p-2 border rounded w-full"
        :class="
          !notFilled || notFilled === 'form_document'
            ? 'mb-5'
            : 'mb-1 border-red-500'
        "
        @change="handleFileChange($event, 'official_document')"
      />
      <p v-if="notFilled === 'official_document'" class="text-red-500">
        Заавал бөглөнө үү!
      </p>

      <label for="form"
        >2. Маягт:<span class="text-red-500 text-sm">*</span></label
      >
      <input
        id="form"
        name="form"
        type="file"
        class="p-2 border rounded w-full"
        :class="
          !notFilled || notFilled === 'official_document'
            ? 'mb-5'
            : 'mb-1 border-red-500'
        "
        @change="handleFileChange($event, 'form')"
      />
      <p v-if="notFilled === 'form_document'" class="text-red-500">
        Заавал бөглөнө үү!
      </p>

      <div class="flex gap-x-1 mb-5">
        <input
          id="publication"
          name="publication"
          type="checkbox"
          v-model="publication"
        /><label for="publication"
          >Олон нийтэд үр дүнг зарлахыг зөвшөөрч байна.</label
        >
      </div>

      <button
        @click="submitAttachment"
        type="submit"
        :disabled="!officialDocument || !formDocument"
        class="text-white p-2 rounded-lg bg-blue-500 enabled:hover:bg-blue-600 disabled:cursor-not-allowed"
      >
        <font-awesome-icon icon="fa-solid fa-paper-plane" class="mr-1" />
        Илгээх
      </button>
    </div>
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      isLoading &&
      isFinished
    "
    class="flex flex-col justify-center items-center w-full min-h-[40vh]"
  >
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      !isLoading &&
      isFinished
    "
    class="flex flex-col justify-center items-center w-full gap-y-3"
  >
    <font-awesome-icon
      icon="fa-solid fa-paper-plane"
      class="w-40 h-40 text-slate-200"
    />
    <p class="uppercase font-bold text-lg text-center text-slate-500">
      Амжилттай илгээгдлээ.
    </p>
    <router-link
      to="/mm"
      class="text-blue-500 underline text-center hover:text-blue-600"
      >Нүүр хуудас руу буцах</router-link
    >
  </div>

  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import PaginationCartTwo from "@/components/ui/PaginationCartTwo.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "MMAssessmentTable",
  components: {
    PaginationCartTwo,
    SpinLoading,
    ErrorModal,
  },
  props: {
    result: Object,
    email: String,
    member: Object,
    memberId: Number,
    answerId: Number,
  },
  setup(props) {
    const updateAnswerStatus = (childQuestion) => {
      childQuestion.isUnanswered =
        childQuestion.answer === undefined || childQuestion.answer === null;
      if (!childQuestion.comment) {
        childQuestion.comment = "";
      }
    };

    //ASSESSMENT TEST
    const assessQuestions = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const errorMessage = ref(null);
    const isLoading = ref(false);

    const fetchAssessmentQuestions = async () => {
      try {
        const res = await clientInstance.get("/get/assessment");
        assessQuestions.value = res.data.result;
        totalPages.value = assessQuestions.value.length;
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      if (props.result) {
        resultPercentage.value = props.result.main_percentage;
        resultId.value = props.result.id;
        currentPage.value++;
      } else {
        await fetchAssessmentQuestions();
      }
    });

    const currentItem = computed(() => {
      return assessQuestions.value[currentPage.value - 1];
    });

    const handlePageChanged = (page) => {
      currentPage.value = page;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleNextPageRequest = (newPage) => {
      const currentQuestions = currentItem.value.questions;
      let allQuestionsAnswered = true;

      currentQuestions.forEach((question) => {
        question.children.forEach((child) => {
          if (child.answer === undefined || child.answer === null) {
            child.isUnanswered = true;
            allQuestionsAnswered = false;
            errorMessage.value = "Бүх асуултанд хариулна уу.";
            setTimeout(() => {
              errorMessage.value = null;
            }, 5000);
          } else {
            child.isUnanswered = false;
          }
        });
      });

      if (!allQuestionsAnswered) {
        return;
      }

      currentPage.value = newPage;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleFileUpload = (event, childQuestion) => {
      const file = event.target.files[0];
      childQuestion.proof_file = file;
    };

    //SAVE ANSWERS
    const resultPercentage = ref(null);
    const trueCount = ref(0);
    const falseCount = ref(0);
    const resultId = ref(null);
    const error = ref(null);

    // const submitAnswers = async () => {
    //   try {
    //     isLoading.value = true;

    //     let totalQuestions = 0;
    //     let localTrueCount = 0;
    //     let localFalseCount = 0;

    //     await assessQuestions.value.forEach((mainQuestion) => {
    //       mainQuestion.questions.forEach((secondLevelQuestion) => {
    //         secondLevelQuestion.children.forEach(async (thirdLevelQuestion) => {
    //           try {
    //             const newAnswerId = props.answerId + 1;

    //             const formData = new FormData();
    //             formData.append("question_id", thirdLevelQuestion.id);
    //             formData.append("answer", thirdLevelQuestion.answer);
    //             formData.append("member_id", props.memberId);
    //             formData.append(
    //               "proof",
    //               thirdLevelQuestion.proof ? true : false
    //             );
    //             formData.append("comment", thirdLevelQuestion.comment);
    //             if (thirdLevelQuestion.proof_file) {
    //               formData.append(`proof_file`, thirdLevelQuestion.proof_file);
    //             }
    //             formData.append("answer_id", newAnswerId);

    //             const res = await clientInstance.post(
    //               "/insert/member-answer",
    //               formData,
    //               {
    //                 headers: {
    //                   "Content-Type": "multipart/form-data",
    //                 },
    //               }
    //             );

    //             if (res.status === 200) {
    //               console.log(
    //                 `Answer for question_id ${thirdLevelQuestion.id} submitted successfully`
    //               );
    //             } else {
    //               error.value = "Алдаа гарлаа!";
    //               setTimeout(() => {
    //                 error.value = null;
    //               }, 5000);
    //             }
    //           } catch (err) {
    //             console.error(
    //               `Error submitting answer for question_id ${thirdLevelQuestion.cid}:`,
    //               err
    //             );
    //           }
    //         });
    //       });
    //     });

    //     const truePercentage = Math.round(
    //       (localTrueCount / totalQuestions) * 100
    //     );
    //     resultPercentage.value = truePercentage;
    //     trueCount.value = localTrueCount;
    //     falseCount.value = localFalseCount;

    //     const resultRes = await clientInstance.post("/insert/result", {
    //       main_percentage: truePercentage,
    //       total_true: localTrueCount,
    //       total_false: localFalseCount,
    //       member_id: props.memberId,
    //     });
    //     if (resultRes.status === 200) {
    //       resultId.value = resultRes.data.result.id;
    //       currentPage.value++;
    //     }
    //   } catch (err) {
    //     console.error("Error submitting answers:", err);
    //   } finally {
    //     isLoading.value = false;
    //   }
    // };

    const submitAnswers = async () => {
      try {
        isLoading.value = true;

        let totalQuestions = 0;
        let localTrueCount = 0;
        let localFalseCount = 0;

        for (const mainQuestion of assessQuestions.value) {
          for (const secondLevelQuestion of mainQuestion.questions) {
            for (const thirdLevelQuestion of secondLevelQuestion.children) {
              try {
                const newAnswerId = props.answerId + 1;

                const formData = new FormData();
                formData.append("question_id", thirdLevelQuestion.id);
                formData.append("answer", thirdLevelQuestion.answer);
                formData.append("member_id", props.memberId);
                formData.append(
                  "proof",
                  thirdLevelQuestion.proof ? true : false
                );
                formData.append("comment", thirdLevelQuestion.comment);
                if (thirdLevelQuestion.proof_file) {
                  formData.append("proof_file", thirdLevelQuestion.proof_file);
                }
                formData.append("answer_id", newAnswerId);

                const res = await clientInstance.post(
                  "/insert/member-answer",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                if (res.status === 200) {
                  totalQuestions++;
                  if (thirdLevelQuestion.answer === 1) {
                    localTrueCount++;
                  } else if (thirdLevelQuestion.answer === 0) {
                    localFalseCount++;
                  }
                } else {
                  error.value = "Алдаа гарлаа!";
                  setTimeout(() => {
                    error.value = null;
                  }, 5000);
                }
              } catch (err) {
                console.error(
                  `Error submitting answer for question_id ${thirdLevelQuestion.id}:`,
                  err
                );
              }
            }
          }
        }

        const truePercentage = Math.round(
          (localTrueCount / totalQuestions) * 100
        );
        resultPercentage.value = truePercentage;
        trueCount.value = localTrueCount;
        falseCount.value = localFalseCount;

        const resultRes = await clientInstance.post("/insert/result", {
          main_percentage: truePercentage,
          total_true: localTrueCount,
          total_false: localFalseCount,
          member_id: props.memberId,
        });

        if (resultRes.status === 200) {
          resultId.value = resultRes.data.result.id;
          currentPage.value++;
        }
      } catch (err) {
        console.error("Error submitting answers:", err);
      } finally {
        isLoading.value = false;
      }
    };

    //ASSESSMENT RESULT
    const officialDocument = ref(null);
    const formDocument = ref(null);
    const publication = ref(true);
    const notFilled = ref(null);
    const isFinished = ref(false);

    const handleFileChange = (event, type) => {
      const file = event.target.files[0];

      if (type === "official_document") {
        officialDocument.value = file;
        if (notFilled.value === "official_document") {
          notFilled.value = null;
        }
      } else if (type === "form") {
        formDocument.value = file;
        if (notFilled.value === "form_document") {
          notFilled.value = null;
        }
      }
    };

    const submitAttachment = async () => {
      if (!officialDocument.value) {
        notFilled.value = "official_document";
        return;
      }

      if (!formDocument.value) {
        notFilled.value = "form_document";
        return;
      }

      try {
        isLoading.value = true;
        const formData = new FormData();
        formData.append("official_document", officialDocument.value);
        formData.append("form", formDocument.value);
        formData.append("publication", publication.value);
        formData.append("result_id", resultId.value);

        const res = await clientInstance.post("/insert/attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          isFinished.value = true;
        }
      } catch (err) {
        console.error("Error submitting attachment:", err);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      updateAnswerStatus,
      handlePageChanged,
      submitAnswers,
      handleNextPageRequest,
      totalPages,
      currentItem,
      currentPage,
      assessQuestions,
      errorMessage,
      isLoading,
      resultPercentage,
      handleFileUpload,
      submitAttachment,
      officialDocument,
      formDocument,
      publication,
      handleFileChange,
      notFilled,
      isFinished,
      error,
    };
  },
};
</script>
