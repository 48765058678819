<template>
  <footer
    class="flex flex-col justify-center items-center bg-green text-white tracking-wide leading-5"
    :class="lang === 'mn' ? 'text-sm' : 'text-xs'"
  >
    <div
      v-motion-slide-visible-once-left
      :duration="1000"
      class="flex max-lg:flex-col gap-10 w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-5"
    >
      <!-- 1st column -->
      <div class="w-full lg:w-1/4">
        <p class="mb-4">
          {{
            lang === "mn"
              ? "Монгол Улс, Улаанбаатар хот, СБД, 1-р хороо, Элчингийн гудамж, Скай Плаза бизнес төв, Д корпус 2 давхар"
              : "2nd floor, D section, “Sky Plaza” Business Center, Embassy Rd, Sukhbaatar district, Ulaanbaatar, Mongolia 14220"
          }}
        </p>
        <p class="mb-4">+976 70110163</p>
        <p class="mb-14">info@miningmongolia.mn</p>
        <div class="flex items-center gap-5 text-lg">
          <a
            v-for="link in socialLinks"
            :key="link.id"
            :href="link.link"
            target="_blank"
          >
            <font-awesome-icon :icon="link.icon" />
          </a>
        </div>
      </div>

      <!-- Rest columns -->
      <ul
        class="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-10"
      >
        <li v-for="(link, index) in categories" :key="index">
          <router-link
            :to="link.route"
            class="font-bold text-base hover:underline"
          >
            {{ lang === "mn" ? link.header : link.header_en }}
          </router-link>

          <ul class="mt-1">
            <li
              v-for="(child, cIndex) in link.children"
              :key="cIndex"
              class="font-light hover:underline"
            >
              <router-link v-if="child.path" :to="child.path" class="block">
                {{ lang === "mn" ? child.title : child.title_en }}
              </router-link>
              <a v-else :href="child.link" target="_blank" class="block">
                {{ lang === "mn" ? child.title : child.title_en }}
              </a>
            </li>
          </ul>
        </li>
      </ul>

      <!-- <ul
        class="w-full lg:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-10"
      >
        <li v-for="parent in mainCategories" :key="parent.cid">
          <router-link
            :to="getCategoryLink(parent)"
            class="font-bold text-base hover:underline"
          >
            {{ lang === "mn" ? parent.category : parent.category_en }}
          </router-link>

          <ul class="mt-1">
            <li
              v-for="child in childCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="font-light hover:underline"
            >
              <router-link
                :to="getChildCategoryLink(parent, child)"
                class="block"
              >
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>

        <li v-for="parent in subCategories" :key="parent.cid">
          <router-link
            :to="`/mm/category/${parent.pid}#${parent.category}`"
            class="font-bold text-base hover:underline"
          >
            {{ lang === "mn" ? parent.category : parent.category_en }}
          </router-link>

          <ul class="mt-1">
            <li
              v-for="child in grandChildCategories.filter(
                (child) => child.pid === parent.cid
              )"
              :key="child.cid"
              class="font-light hover:underline"
            >
              <router-link :to="`/mm/page/${child.cid}`" class="block">
                {{ lang === "mn" ? child.category : child.category_en }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul> -->
    </div>

    <div
      v-motion-slide-visible-once-bottom
      :duration="1000"
      class="flex justify-start items-center w-full min-[1200px]:w-4/5 max-[1199px]:p-5 mb-4 text-xs"
    >
      <a href="https://bowsys.mn" target="_self" class="hover:underline"
        >&copy; Developed By BOW LLC</a
      >
    </div>
  </footer>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MMFooter",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const socialLinks = computed(() => store.getters["client/getSocialLinks"]);

    onMounted(async () => {
      try {
        await store.dispatch("client/fetchSocialLinks");
      } catch (err) {
        return err;
      }
    });

    const categories = ref([
      {
        header: "Ассоциацийн тухай",
        header_en: "About us",
        route: "/mm/about",
        children: [
          {
            title: "Бидний тухай",
            title_en: "About us",
            path: "/mm/about#1",
          },
          {
            title: "Бүтэц зохион байгуулалт",
            title_en: "Organization",
            path: "/mm/about#2",
          },
        ],
      },
      {
        header: "Үйл ажиллагаа",
        header_en: "Activity",
        route: "/mm/activity",
        children: [
          {
            title: "Үндсэн ажлын хэсгүүд",
            title_en: "Main working groups",
            path: "/mm/activity#1",
          },
          {
            title: "Төсөл хөтөлбөр",
            title_en: "Projects",
            path: "/mm/activity#2",
          },
          {
            title: "Хууль эрхзүй",
            title_en: "Legal",
            path: "/mm/activity#3",
          },
        ],
      },
      {
        header: "Гишүүнчлэл",
        header_en: "Membership",
        route: "/mm/member",
        children: [
          {
            title: "Зөвлөлийн гишүүд",
            title_en: "Board members",
            path: "/mm/member#1",
          },
          {
            title: "Үндсэн гишүүд",
            title_en: "Members",
            path: "/mm/member#2",
          },
          {
            title: "Гишүүнээр элсэх",
            title_en: "Become a member",
            path: "/mm/member#3",
          },
        ],
      },
      {
        header: "Арга хэмжээ",
        header_en: "Events",
        route: "/mm/blog/13=1",
        children: [
          {
            title: "PDAC",
            title_en: "PDAC",
            link: "https://pdacmongolia.com/",
          },
          {
            title: "IMARC",
            title_en: "IMARC",
            link: "https://imarcglobal.com",
          },
          {
            title: "MINING WEEK",
            title_en: "MINING WEEK",
            link: "https://miningweek.mn",
          },
          {
            title: "Бүсийн чуулган",
            title_en: "Бүсийн чуулган",
            path: "/mm/blog/17=1",
          },
          {
            title: "Аж үйлдвэржилтийн үндэсний зөвлөгөөн",
            title_en: "Аж үйлдвэржилтийн үндэсний зөвлөгөөн",
            path: "/mm/blog/18=1",
          },
        ],
      },
      {
        header: "Төсөл хөтөлбөр",
        header_en: "Projects",
        route: "/mm/activity#2",
        children: [
          {
            title: "Хариуцлагатай уул уурхай",
            title_en: "Responsible mining",
            path: "/mm/page/20",
          },
          {
            title: "Бидний мэдэхгүй уул уурхай",
            title_en: "Mining we do not know",
            path: "/mm/page/21",
          },
          {
            title: "Уул уурхай 101",
            title_en: "Mining 101",
            path: "/mm/page/22",
          },
          {
            title: "Future miners",
            title_en: "Future miners",
            path: "/mm/page/23",
          },
          {
            title: "Сарын онцлох гишүүн",
            title_en: "Member of the Month",
            path: "/mm/page/24",
          },
        ],
      },
    ]);

    return {
      lang,
      socialLinks,
      categories,
    };
  },
};
</script>
