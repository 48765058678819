<template>
  <div class="flex justify-center">
    <main
      class="flex flex-col items-center w-full min-[1200px]:w-4/5 min-h-[50vh] max-[1199px]:p-3 lg:my-10"
    >
      <h1 class="uppercase font-semibold text-blue-500 text-xl text-center">
        Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
      </h1>

      <div
        class="flex max-sm:flex-col w-full sm:w-1/2 gap-y-5 gap-x-10 mt-5 sm:mt-10"
      >
        <router-link
          to="/mm/member-assessment"
          class="flex flex-col items-center justify-center gap-4 shadow-lg border border-gray-200 rounded-xl p-8 bg-white text-gray-600 text-center uppercase transition-all duration-300 hover:text-green-600 hover:shadow-xl hover:border-green-500"
        >
          <font-awesome-icon
            icon="fa-regular fa-building"
            class="text-6xl text-gray-300 transition-transform duration-300 hover:scale-110"
          />
          <span class="text-lg font-medium">Гишүүн байгууллага</span>
        </router-link>
        <router-link
          to="/mm/self-assessment"
          class="flex flex-col items-center justify-center gap-4 shadow-lg border border-gray-200 rounded-xl p-8 bg-white text-gray-600 text-center uppercase transition-all duration-300 hover:text-green-600 hover:shadow-xl hover:border-green-500"
        >
          <font-awesome-icon
            icon="fa-regular fa-user"
            class="text-6xl text-gray-300 transition-transform duration-300 hover:scale-110"
          />
          <span class="text-lg font-medium">Өөрийн сонирхлоор</span>
        </router-link>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "MMAssessment",
};
</script>
