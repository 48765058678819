<template>
  <main>
    <ul class="h-[95vh] space-y-5 mt-3 p-3 text-sm text-nowrap">
      <li v-for="(route, index) in MMRoutes" :key="index">
        <div
          v-if="route.name === 'Нүүр хуудас' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Вэбийн хуудаснууд
          </p>
        </div>
        <div
          v-if="route.name === 'Хэрэглэгчдийн хүсэлт' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Хүсэлт, үнэлгээ
          </p>
        </div>
        <div
          v-if="route.name === 'Арилжааны мэдээлэл' && !hiddenNavbar"
          class="relative text-center my-6"
        >
          <hr class="border-t border-gray-300" />
          <p
            class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
          >
            Уул уурхайн бүтээгдэхүүн
          </p>
        </div>
        <div
          @click="route.children ? toggleMenu(index) : navigateTo(route.to)"
          class="flex items-center justify-between rounded-md cursor-pointer"
          :class="{
            'justify-center': hiddenNavbar,
            'text-green': isRoute === `/admin${route.to}`,
            'hover:text-green':
              isRoute !== `/admin${route.to}` && route.to !== null,
          }"
        >
          <div
            class="flex items-center"
            :class="hiddenNavbar ? 'justify-center' : 'gap-2'"
          >
            <font-awesome-icon
              :icon="`fa-solid fa-${route.icon}`"
              :class="hiddenNavbar ? 'text-lg' : 'text-sm'"
            />
            <h6 :class="hiddenNavbar ? 'hidden' : 'block'">
              {{ route.name }}
            </h6>
          </div>
          <font-awesome-icon
            v-if="route.children && !hiddenNavbar"
            :icon="
              menuIndices.includes(index)
                ? 'fa-solid fa-chevron-down'
                : 'fa-solid fa-chevron-left'
            "
          />
        </div>

        <ul
          v-if="
            route.children &&
            route.children.length &&
            menuIndices.includes(index)
          "
          class="pl-7 mt-3 space-y-3 overflow-hidden"
        >
          <li v-for="(child, childIndex) in route.children" :key="childIndex">
            <span v-if="child.grandchildren && child.grandchildren.length"
              ><font-awesome-icon icon="fa-regular fa-circle" class="mr-1" />{{
                child.name
              }}</span
            >
            <router-link
              v-else
              :to="`/admin${child.to}`"
              :class="
                isRoute === `/admin${child.to}`
                  ? 'text-green'
                  : 'hover:text-green'
              "
            >
              <font-awesome-icon
                v-if="isRoute === `/admin${child.to}`"
                icon="fa-regular fa-circle-dot"
                class="mr-1"
              />
              <font-awesome-icon
                v-else
                icon="fa-regular fa-circle"
                class="mr-1"
              />
              {{ child.name }}
            </router-link>

            <ul
              v-if="child.grandchildren && child.grandchildren.length"
              class="pl-5 mt-3 space-y-3 overflow-hidden"
            >
              <li
                v-for="(child, childIndex) in child.grandchildren"
                :key="childIndex"
              >
                <router-link
                  :to="`/admin${child.to}`"
                  :class="
                    isRoute === `/admin${child.to}`
                      ? 'text-green'
                      : 'hover:text-green'
                  "
                >
                  {{ child.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </main>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MMAdminNavbar",
  props: {
    hiddenNavbar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const account = computed(() => store.getters["admin/getAdminInformation"]);

    const router = useRouter();
    const route = useRoute();
    const isRoute = ref(route.path);

    watch(
      () => route.path,
      (newRoute) => {
        isRoute.value = newRoute;
      }
    );

    // const isLoading = ref(true);
    // const staticCategories = ref([]);

    // const fetchStaticCategories = async () => {
    //   try {
    //     const res = await adminInstance.get("/get/static-page-category");
    //     if (res.status === 200) {
    //       staticCategories.value = res.data.mainCategories;

    //       const otherPagesRoute = MMRoutes.find(
    //         (route) => route.name === "Статик хуудас"
    //       );
    //       if (otherPagesRoute) {
    //         otherPagesRoute.children = staticCategories.value.map(
    //           (category) => ({
    //             name: category.category,
    //             to: `/edit-static/${category.cid}`,
    //             grandchildren: category.children.map((child) => ({
    //               name: child.category,
    //               to: `/edit-static/${child.cid}`,
    //             })),
    //           })
    //         );
    //       }
    //     }
    //   } catch (err) {
    //     console.error("Error fetching static categories:", err);
    //     return err;
    //   } finally {
    //     isLoading.value = false;
    //   }
    // };

    const MMRoutes = [
      {
        name: "Хянах самбар",
        to: "/dashboard",
        icon: "chart-line",
      },
      {
        name: "Вэбсайтын тохиргоо",
        to: null,
        icon: "sliders",
        children: [
          {
            name: "Цэс тохируулах",
            to: "/link",
          },
          {
            name: "Сошиал холбоос",
            to: "/social-links",
          },
        ],
      },
      {
        name: "Нүүр хуудас",
        to: "/slides",
        icon: "home",
      },
      {
        name: "Ассоциацийн зөвлөл",
        to: "/about",
        icon: "users",
      },
      {
        name: "Мэдээ мэдээлэл",
        to: null,
        icon: "newspaper",
        children: [
          {
            name: "Мэдээ нэмэх",
            to: "/add-news",
          },
          {
            name: "Мэдээ засварлах",
            to: "/update-news",
          },
        ],
      },
      {
        name: "Гишүүнчлэл",
        to: "/membership",
        icon: "user",
      },
      {
        name: "Төсөл хөтөлбөр",
        to: null,
        icon: "list-check",
        children: [
          {
            name: "ХУУ - Файлууд",
            to: "/document",
            icon: "file-pdf",
          },
          {
            name: "Уул уурхай 101 - Бичлэг",
            to: "/video",
            icon: "video",
          },
        ],
      },
      {
        name: "Хэрэглэгчдийн хүсэлт",
        to: "/request-list",
        icon: "list-check",
      },
      {
        name: "ХУУ - Өөрийн үнэлгээ",
        to: null,
        icon: "scale-balanced",
        children: [
          {
            name: "Үнэлгээний асуултууд",
            to: "/update-assessment",
          },
          {
            name: "Үнэлгээний хариу",
            to: "/assessment-result",
          },
          {
            name: "Гишүүн байгууллага",
            to: "/member-companies",
          },
        ],
      },
      {
        name: "Гишүүн байгууллагууд",
        to: "/companies",
        icon: "building",
      },
      {
        name: "Арилжааны мэдээлэл",
        to: "/trade",
        icon: "arrow-trend-up",
      },
    ];

    const menuIndices = ref([]);
    const toggleMenu = (index) => {
      const idx = menuIndices.value.indexOf(index);
      if (idx > -1) {
        menuIndices.value.splice(idx, 1);
      } else {
        menuIndices.value.push(index);
      }
    };

    const navigateTo = (to) => {
      router.push(`/admin${to}`);
    };

    onMounted(async () => {
      // await fetchStaticCategories();
      MMRoutes.forEach((route, index) => {
        if (route.children) {
          route.children.forEach((child) => {
            if (`/admin${child.to}` === isRoute.value) {
              menuIndices.value.push(index);
            }
          });
        }
      });
    });

    return {
      isRoute,
      MMRoutes,
      menuIndices,
      toggleMenu,
      navigateTo,
      account,
    };
  },
};
</script>
