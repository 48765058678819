<template>
  <main class="flex justify-center">
    <div
      class="w-full min-[1200px]:w-4/5 min-h-[50vh] max-[1200px]:mx-3 py-5 md:py-10"
    >
      <img
        src="https://miningmongolia.mn/static/1721588363751.png"
        alt="future miners"
        class="w-40 object-contain"
      />

      <p class="text-sm text-justify mt-3 tracking-wide">
        {{
          lang === "mn"
            ? "“Монгол Улсын эрдэс баялгийн салбарын боловсон хүчний хэрэгцээ шаардлага болон боломжит олон улсын тэтгэлгийн танилцуулга” цуврал уулзалт."
            : "'Introduction of the personnel needs of Mongolia`s mineral resources sector and possible international scholarships' series of meetings."
        }}
      </p>
    </div>
  </main>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "FutureMiners",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    return { lang };
  },
};
</script>
