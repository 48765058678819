<template>
  <header class="w-full bg-white tracking-wide text-nowrap">
    <!-- for small screen -->
    <div
      class="flex justify-between items-center text-white min-[1200px]:hidden bg-green px-5 py-2.5"
    >
      <button @click="toggleDrawer()" class="border border-white px-2 py-1">
        <font-awesome-icon icon="fa-solid fa-bars" class="text-sm md:text-xl" />
      </button>
      <button @click="toggleLanguage()" class="text-sm md:text-base">
        <font-awesome-icon icon="fa-solid fa-globe" class="mr-1" />{{
          lang === "mn" ? "Монгол" : "English"
        }}
      </button>
    </div>

    <transition name="fade" class="min-[1200px]:hidden">
      <div
        v-if="isDrawerOpen"
        class="fixed top-0 left-0 z-20 h-screen w-[18rem] bg-green text-white text-sm p-5"
      >
        <button @click="toggleDrawer()" class="absolute top-3 right-3">
          <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
        </button>

        <ul class="mt-10"></ul>
      </div>
    </transition>

    <!-- for large screen -->
    <nav class="hidden min-[1200px]:flex flex-col items-center bg-green">
      <ul
        class="flex justify-between items-center min-[1350px]:w-4/5 max-[1349px]:px-3 uppercase text-white text-sm py-3"
      >
        <li
          class="flex justify-center items-center text-center border-l border-white px-3 py-2 hover:bg-lightGreen"
          :class="isRoute === `/mm` ? 'bg-lightGreen' : ''"
        >
          <router-link
            to="/mm"
            class="flex justify-center items-center w-full h-full"
          >
            <font-awesome-icon icon="fa-solid fa-house" />
          </router-link>
        </li>
        <li
          v-for="(parent, index) in mainCategories"
          :key="index"
          class="flex justify-center items-center w-full text-center group relative border-l border-white px-3 py-1 hover:bg-lightGreen"
          :class="isRoute === parent.to ? 'bg-lightGreen' : ''"
        >
          <router-link
            :to="parent.to"
            class="flex justify-center items-center w-full h-full"
          >
            {{ lang === "mn" ? parent.name : parent.name_en }}
            <font-awesome-icon
              v-if="parent.children"
              icon="fa-solid fa-angle-down"
              class="text-xs ml-0.5"
            />
          </router-link>

          <ul
            class="invisible group-hover:visible absolute top-7 left-0 z-20 block bg-green w-full text-pretty"
          >
            <li
              v-for="(child, cIndex) in parent.children"
              :key="cIndex"
              class="relative p-3 border-b border-gray-200 hover:bg-lightGreen group"
            >
              <a v-if="child.link" :href="child.link" target="_blank">
                {{ lang === "mn" ? child.name : child.name_en }}
              </a>
              <router-link v-else :to="child.to">
                {{ lang === "mn" ? child.name : child.name_en }}
              </router-link>
            </li>
          </ul>
        </li>
        <li
          class="flex justify-center items-center text-center border-x border-white px-3 py-2 hover:bg-lightGreen"
        >
          <button
            @click="toggleMoreMenu()"
            class="flex justify-center items-center w-full h-full"
          >
            <font-awesome-icon icon="fa-solid fa-ellipsis" />
          </button>
        </li>
      </ul>

      <!-- MORE MENU -->
      <ul
        v-if="isMoreMenu"
        class="flex items-center min-[1350px]:w-4/5 max-[1349px]:px-3 uppercase text-white text-sm py-3"
      >
        <li
          v-for="(parent, index) in subCategories"
          :key="index"
          class="flex justify-center items-center text-center group relative border-l border-white px-3 py-1 hover:bg-lightGreen"
          :class="isRoute === parent.to ? 'bg-lightGreen' : ''"
        >
          <router-link :to="parent.to">
            {{ lang === "mn" ? parent.name : parent.name_en }}
            <font-awesome-icon
              v-if="parent.children"
              icon="fa-solid fa-angle-down"
              class="text-xs ml-1"
            />
          </router-link>

          <ul
            class="invisible group-hover:visible absolute top-7 left-0 z-[1200] block bg-green w-full text-pretty"
          >
            <li
              v-for="(child, pIndex) in parent.children"
              :key="pIndex"
              class="relative p-3 border-b border-gray-200 hover:bg-lightGreen group"
            >
              <router-link :to="child.to">
                {{ lang === "mn" ? child.name : child.name_en }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MMNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const route = useRoute();
    const isRoute = computed(() => route.path);

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    const mainCategories = ref([
      {
        name: "Ассоциацийн зөвлөл",
        name_en: "About us",
        to: "/mm/about",
        children: [
          {
            name: "Бидний тухай",
            name_en: "About us",
            to: "/mm/about#1",
          },
          {
            name: "Бүтэц зохион байгуулалт",
            name_en: "Organization",
            to: "/mm/about#2",
          },
        ],
      },
      {
        name: "Үйл ажиллагаа",
        name_en: "Activity",
        to: "/mm/activity",
        children: [
          {
            name: "Үндсэн ажлын хэсгүүд",
            name_en: "Main working groups",
            to: "/mm/activity#1",
          },
          {
            name: "Төсөл хөтөлбөр",
            name_en: "Projects",
            to: "/mm/activity#2",
          },
          {
            name: "Хууль эрх зүй",
            name_en: "Legal",
            to: "/mm/activity#3",
          },
        ],
      },
      {
        name: "Мэдээлэл",
        name_en: "News & Stories",
        to: "/mm/blog/8=1",
      },
      {
        name: "Гишүүнчлэл",
        name_en: "Membership",
        to: "/mm/membership",
        children: [
          {
            name: "Зөвлөлийн гишүүд",
            name_en: "Board members",
            to: "/mm/membership#1",
          },
          {
            name: "Үндсэн гишүүд",
            name_en: "Members",
            to: "/mm/membership#2",
          },
          {
            name: "Гишүүнээр элсэх",
            name_en: "Become a member",
            to: "/mm/membership#3",
          },
        ],
      },
      {
        name: "Арга хэмжээ",
        name_en: "Events",
        to: "/mm/blog/13=1",
        children: [
          {
            name: "PDAC",
            name_en: "PDAC",
            link: "https://pdacmongolia.com/",
          },
          {
            name: "IMARC",
            name_en: "IMARC",
            link: "https://imarcglobal.com",
          },
          {
            name: "Mining week",
            name_en: "Mining week",
            link: "https://miningweek.mn",
          },
          {
            name: "Бүсийн чуулган",
            name_en: "Бүсийн чуулган",
            to: "/mm/blog/17=1",
          },
          {
            name: "Аж үйлдвэржилтийн зөвөлгөөн",
            name_en: "Аж үйлдвэржилтийн зөвөлгөөн",
            to: "/mm/blog/18=1",
          },
        ],
      },
      {
        name: "Холбоо барих",
        name_en: "Contact",
        to: "/mm/contact",
      },
    ]);

    const subCategories = ref([
      {
        name: "Төсөл хөтөлбөр",
        name_en: "",
        to: "",
        children: [],
      },
      {
        name: "Үнэлгээ",
        name_en: "Assessment",
        to: "/mm/assessment",
      },
      {
        name: "Арилжааны мэдээлэл",
        name_en: "Trade report",
        to: "/mm/trade",
      },
      {
        name: "МҮУУА 30 жил",
        name_en: "30 year anniversary",
        to: "/mm/anniversary",
      },
    ]);

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    const isMoreMenu = ref(false);
    const toggleMoreMenu = () => {
      isMoreMenu.value = !isMoreMenu.value;
    };

    return {
      lang,
      isDrawerOpen,
      toggleDrawer,
      toggleLanguage,
      mainCategories,
      subCategories,
      isRoute,
      isMoreMenu,
      toggleMoreMenu,
    };
  },
};
</script>
